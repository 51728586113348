import { keyframes } from 'styled-components'

export const strokeDraw = keyframes`
  100% {
    stroke-dashoffset: 0;
  }
`

export const scalePop = keyframes`
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
`

export const slideInDown = keyframes`
  0% {
    transform: scale(0.5);
    transform-origin: 100% 0%;
    opacity: 0%;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 0%;
    opacity: 100%;
  }
`
export const slideOutUp = keyframes`
  0% {
    transform: scale(1);
    transform-origin: 100% 0%;
    opacity: 100%;
  }
  100% {
    transform: scale(0.5);
    transform-origin: 100% 0%;
    opacity: 0%;
  }
`

export const FadeInAnimation = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
`
